import styled from "styled-components";
import { Rem, BP } from "../../commons/Theme";

const maxWidth = 1248;
const breakpoint = (props) => 
  props.breakpoint === "big" ? BP.big 
    : props.breakpoint === "large" ? BP.large 
      : props.breakpoint === "desktop" ? BP.desktop 
        : props.breakpoint === "ipad" ? BP.ipad 
          : props.breakpoint === "tablet" ? BP.tablet 
            : BP.smartphone;

export const StyledTwoColumnLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${(props) => props.reverseAfterBreakpoint ? "column-reverse" : "column"};
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto ${Rem(50)};
  padding-top: ${(props) => props.header ? Rem(50) : Rem(0) };

  @media (${breakpoint}) {
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    margin: ${Rem(10)} auto ${Rem(70)};
  }
`;

export const StyledTwoColumnLayoutFirst = styled.div`
  width: 100%;
  height: auto;
  align-self: ${(props) => props.alignSelf};

  @media (${breakpoint}) {
    width: ${(props) => props.widthPercentage}%;
  }

  & > * {
    max-width: 100%;
    
    margin-left: auto; 
    margin-right: 0;
    
    @media (${breakpoint}) {
      max-width: ${(props) => props.isFull ? "100%" : Rem(maxWidth * props.widthPercentage / 100)};
    }
  }
`;

export const StyledTwoColumnLayoutSecond = styled.div`
  width: 100%;
  height: auto;
  align-self: ${(props) => props.alignSelf};

  @media (${breakpoint}) {
    width: ${(props) => props.widthPercentage}%;
  }

  & > * {
    max-width: 100%;
    @media (${breakpoint}) {
      max-width: ${(props) => props.isFull ? "100%" : Rem(maxWidth * props.widthPercentage / 100)};
    }
  }
`;
